body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #454d55 !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

a.page-link {
	background-color: #343a40;
	color: #17a2b8;
}

a.page-link:hover {
	background-color: #17a2b8;
	color: white;
}

span.page-link {
	background-color: #17a2b8 !important;
}
